html, body {
  font-family: 'Lato';
  font-weight: 700; }

p {
  font-size: 24px; }

button::-moz-focus-inner {
  border: 0; }

.title {
  font-size: 48px; }

#menu {
  font-size: 22px;
  display: inline-block; }

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none; }

.image-heading {
  font-size: 24px;
  padding-top: 3%;
  padding-bottom: 5%; }

a, a:link {
  text-decoration: none;
  color: #333333;
  outline: none; }

/*# sourceMappingURL=app.css.map */
